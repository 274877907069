export const DAYS_TO_RETAIN_DATA_AFTER_PROJECT_CLOSED = 15;
export const DOMAIN = process.env.API_URL;
export * as ROUTES from './routes';
export * as COOKIE_NAMES from './cookies';
export * as THIRD_PARTY_CHECKS from './third_party_checks';
export const DEFAULT_AVATAR = '/images/avatar.png';
export const ADMIN_AVATAR = '/images/GTI_Logo_Mini@2x.png';
export const TWILIO_ADMIN_IDENTITY = '123456789';
export const UPLOADED_FILE_ALLOWED_EXTENSIONS = ['7z', 'csv', 'doc', 'docx', 'jpeg', 'jpg', 'pdf', 'png', 'ppt', 'pptx', 'rar', 'xls', 'xlsx', 'zip'];
export const DEBOUNCE_WAIT_TIME = 800; //ms

export const DEFAULT_TIME_FORMAT = 'DD/MM/YY';
export const DEFAULT_DATE_PICKER_TIME_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_SHOW_TIME_FORMAT = 'MMMM DD, YYYY';
export const DEFAULT_DATE_TIME_NOW = 'YYYY-MM-DD HH:mm:ss';
export const DEFAULT_NOTIFICATION_DATE_TIME_NOW = 'yyyy-MM-dd HH:mm:ss';
export const DEFAULT_SHOW_DATE_FORMAT = 'DD/MM/YYYY';
export const CURRENCY_SYMBOLS = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  $: '$'
};
export const REPORT_STATUS = {
  PUBLIC: 'Public',
  DRAFT: 'Draft'
};
export const CURRENCY_AREAS = [{
  label: 'USD',
  value: 'USD'
}, {
  label: 'GBP',
  value: 'GBP'
}, {
  label: 'EUR',
  value: 'EUR'
}];
export const PROJECT_REPORT_STATE = {
  DRAFT: '1',
  PUBLIC: '2'
};
export const PENDING_ID = '1';
export const MATCHED_ID = '2';
export const SCOPING_ID = '3';
export const PROJECT_ACTIVATED_ID = '4';
export const COMPLETE_ID = '5';
export const CLOSED_ID = '6';
export const GTI_STAFF = 'GTI staff';
export const USER_TAG = {
  id: 8,
  name: 'User Tag'
};
export const TAG_COLORS = {
  1000: 'magenta',
  1010: 'red',
  1020: 'purple',
  1030: 'orange',
  1040: 'blue',
  1050: 'lime',
  1060: 'green',
  1070: 'cyan'
};
export const TAG_COLORS_HEX = {
  1000: '#f0f',
  1010: '#f00',
  1020: '#800080',
  1030: '#ffa500',
  1040: '#00f',
  1050: '#0f0',
  1060: '#008000',
  1070: '#0ff'
};
export const TAG_COLORS_RGBA = {
  1000: '255, 0, 255',
  1010: '255, 0, 0',
  1020: '128, 0, 128',
  1030: '255, 165, 0',
  1040: '0, 0, 255',
  1050: '0, 255, 0',
  1060: '0, 128, 0',
  1070: '0, 255, 255'
};
export const PROJECT_TYPE = {
  STANDARD: 'external,standard',
  BATCH: 'batch',
  DRAFT: 'draft'
};
export const REPORT_VIEW_STATE = {
  CREATE: 'create',
  REVIEW: 'review'
};
export const PROJECT_STATUS_DISPLAY_KEY_VALUE = {
  Pending: 1,
  Matched: 3,
  Activated: 4,
  Completed: 5,
  Closed: 6
};
export const REPORT_SELECTION_PERMISSION = {
  REPORT_SECTION_PERMISSION_PUBLIC: '0',
  REPORT_SECTION_PERMISSION_PRIVATE: '1'
};
export const REPORT_TYPE = {
  REPORTSECTIONTYPE_LOGO: '1',
  REPORTSECTIONTYPE_SUMMARY: '2',
  REPORTSECTIONTYPE_INDIVIDUAL: '3',
  REPORTSECTIONTYPE_ATMOSPHERIC: '4',
  REPORTSECTIONTYPE_CHART: '5',
  REPORTSECTIONTYPE_IMAGE: '6',
  REPORTSECTIONTYPE_ATTACHMENT: '7'
};
export const PROJECT_STATUS_DISPLAY = {
  MATCHED: 'Matched',
  PENDING: 'Pending',
  SCOPING: 'Scoping',
  CLOSED: 'Closed',
  ACTIVATED: 'Activated',
  COMPLETED: 'Completed'
};
export const APP_VERSION = '0.96.2'; // x-release-please-version

export const ADMIN_ROLE = 'Administrator';
export const CLIENT_ROLE = 'Client Mode';
export const PARTNER_ROLE = 'Partner Mode';
export const MAX_UPLOAD_SIZE = 262144000;
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { pick } from 'lodash';
import { action, observable } from 'mobx';
import moment from 'moment';
import { changeStatusAction, getListCategoryAction, getProjectDetail, getProjectReports, getProjectType, updateProject, updateProjectDescription, updateReport } from '@gtintel/platform-api';
import { CLOSED_ID, COMPLETE_ID, CURRENCY_SYMBOLS, DEFAULT_DATE_TIME_NOW, PROJECT_REPORT_STATE, REPORT_STATUS } from '@gtintel/platform-constants';
import { formatDateForApi } from '../utils/other';
const DEFAULT_PROJECT_CURRENCY = 'USD';
let AdminProjectDetailStore = (_class = class AdminProjectDetailStore {
  constructor(rootStore) {
    this.rootStore = void 0;
    _initializerDefineProperty(this, "projectDetail", _descriptor, this);
    _initializerDefineProperty(this, "projectTypes", _descriptor2, this);
    _initializerDefineProperty(this, "tagCategoryList", _descriptor3, this);
    _initializerDefineProperty(this, "projectReports", _descriptor4, this);
    _initializerDefineProperty(this, "isLoading", _descriptor5, this);
    _initializerDefineProperty(this, "batchType", _descriptor6, this);
    _initializerDefineProperty(this, "isUpdateSuccess", _descriptor7, this);
    this.rootStore = rootStore;
  }
  async fetchProjectDetail(projectId) {
    this.isLoading = true;
    const response = await getProjectDetail(projectId);
    this.projectDetail = response;
    this.isLoading = false;
  }
  async fetchProjectType() {
    this.isLoading = true;
    const response = await getProjectType();
    this.projectTypes = response;
    this.isLoading = false;
  }
  async fetchCategoryTag() {
    this.isLoading = true;
    const response = await getListCategoryAction();
    this.tagCategoryList = response.results;
    this.isLoading = false;
  }
  async fetchProjectReports(projectId) {
    this.isLoading = true;
    const projectReports = await getProjectReports(projectId);
    projectReports.results = Array.isArray(projectReports.results) ? projectReports.results.map(projectReport => ({
      ...projectReport,
      // @ts-ignore
      projectReportDetails: projectReport.projectreportsDetails
    })) : [];
    this.projectReports = projectReports;
    this.isLoading = false;
  }
  async setBatchType(newType) {
    this.batchType = newType;
  }
  async submitEditProjectDetail(data) {
    const projectId = this.projectDetail.id;
    const convertPriceStringToNumber = price => Number(price.replace(/[^0-9.]+/g, ''));
    const {
      additionalInfo,
      currency,
      cxLeadId,
      finalPrice,
      highLevelComments,
      npCompletionDate,
      parentId,
      partnerPrice,
      priceBreakdown,
      projectDescription,
      projectName,
      projectType,
      qaRequired,
      requestAnonymity,
      requestDate,
      tagIds,
      flags
    } = data;
    const updateProjectData = {
      id: projectId.toString(),
      additional_info: additionalInfo,
      currency: !currency ? CURRENCY_SYMBOLS[DEFAULT_PROJECT_CURRENCY] : CURRENCY_SYMBOLS[currency],
      cx_lead_id: cxLeadId,
      description: projectDescription,
      final_price: convertPriceStringToNumber(finalPrice),
      high_level_comments: highLevelComments,
      name: projectName,
      np_completion_date: formatDateForApi(npCompletionDate),
      parent_id: parentId,
      partner_price: convertPriceStringToNumber(partnerPrice),
      price_breakdown: priceBreakdown,
      project_type_id: projectType,
      qa_required: qaRequired,
      request_anonymity: requestAnonymity,
      requested_completion_date: formatDateForApi(requestDate),
      tags: tagIds,
      flags
    };
    try {
      this.isLoading = true;
      await updateProject(projectId, projectType, updateProjectData);
      this.isUpdateSuccess = true;
    } catch (error) {
      this.isLoading = false;
      this.isUpdateSuccess = false;
    }
  }
  async updateProjectDescription(id, data) {
    const updateData = pick(data, ['additional_info', 'address', 'dob', 'email', 'is_entity', 'is_individual', 'is_online', 'is_rural', 'is_source_of_wealth', 'jurisdiction', 'phone_number', 'report_type', 'service_name', 'site_name', 'social_media', 'social_media_review', 'tax_id']);
    try {
      this.isLoading = true;
      await updateProjectDescription(id, updateData);
      this.isUpdateSuccess = true;
    } catch (error) {
      console.log(error);
      this.isLoading = false;
      this.isUpdateSuccess = false;
    }
  }
  async completeProject(projectId) {
    this.isLoading = true;
    try {
      if (this.projectReports?.count > 0) {
        const currentProjectReport = this.projectReports.results[0];
        const dateNow = moment().format(DEFAULT_DATE_TIME_NOW);
        const projectReportData = {
          ...currentProjectReport,
          state_display: REPORT_STATUS.PUBLIC,
          published_date: dateNow,
          state: PROJECT_REPORT_STATE.PUBLIC
        };
        await updateReport(projectReportData, currentProjectReport.id);
        await changeStatusAction(projectId, {
          status: parseInt(COMPLETE_ID)
        });
        this.isUpdateSuccess = true;
      } else {
        await changeStatusAction(projectId, {
          status: parseInt(COMPLETE_ID)
        });
        this.isUpdateSuccess = true;
      }
    } catch (error) {
      this.isUpdateSuccess = false;
    }
    this.isLoading = false;
  }
  async closeProject(projectId) {
    this.isLoading = true;
    try {
      await changeStatusAction(projectId, {
        status: parseInt(CLOSED_ID)
      });
      this.isUpdateSuccess = true;
    } catch (error) {
      this.isUpdateSuccess = false;
    }
    this.isLoading = false;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "projectDetail", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return {};
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "projectTypes", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tagCategoryList", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "projectReports", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "batchType", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return '';
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isUpdateSuccess", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "fetchProjectDetail", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchProjectDetail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchProjectType", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchProjectType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchCategoryTag", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCategoryTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchProjectReports", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchProjectReports"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setBatchType", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setBatchType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitEditProjectDetail", [action], Object.getOwnPropertyDescriptor(_class.prototype, "submitEditProjectDetail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateProjectDescription", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateProjectDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "completeProject", [action], Object.getOwnPropertyDescriptor(_class.prototype, "completeProject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeProject", [action], Object.getOwnPropertyDescriptor(_class.prototype, "closeProject"), _class.prototype)), _class);
export { AdminProjectDetailStore as default };
import { COOKIE_NAMES } from '@gtintel/platform-constants';
import { setCookie } from '@gtintel/platform-utils';
import { api, setAuthorizationHeader } from '..';
function setCookieInforUser(data) {
  const mergeUser = data[0];
  mergeUser.tags = [];
  mergeUser.biography = '';
  mergeUser.tag_groups = [];
  mergeUser.notes = '';
  data.forEach(user => {
    if (user?.is_client) {
      mergeUser.id_client = user?.id;
    }
    if (user?.is_network_partner) {
      mergeUser.id_partner = user?.id;
      mergeUser.partner_role = user?.role;
      mergeUser.enable_assignments = user?.enable_assignments;
    }
  });
  setCookie(COOKIE_NAMES.INFOR_USER, mergeUser);
}
function setAuthCookies(token, refreshToken, npToken, npRefresh) {
  setCookie(COOKIE_NAMES.TOKEN, token);
  setCookie(COOKIE_NAMES.REFRESH_TOKEN, refreshToken);
  setCookie(COOKIE_NAMES.CLIENT_TOKEN, token);
  setCookie(COOKIE_NAMES.CLIENT_REFRESH, refreshToken);
  setCookie(COOKIE_NAMES.NP_TOKEN, token);
  setCookie(COOKIE_NAMES.NP_REFRESH, refreshToken);
  if (npToken) {
    setCookie(COOKIE_NAMES.NP_TOKEN, npToken);
    setCookie(COOKIE_NAMES.NP_REFRESH, npRefresh);
  }
}
export const loginStepOne = data => api.auth.login(data).then(response => {
  const token = response.data.token;
  const refreshToken = response.data.refresh;
  const npToken = response.data.partner_token ? response.data.partner_token : null;
  const npRefresh = response.data.partner_refresh ? response.data.partner_refresh : null;
  if (token) {
    setAuthCookies(token, refreshToken, npToken, npRefresh);
    setAuthorizationHeader(token);
  }
  response.data.is2FA = !token;
  return Promise.resolve(response.data);
}).catch(e => Promise.reject(e.response));
export const loginStepTwo = data => api.auth.loginWithVerifyCode(data).then(response => {
  const token = response.data.token;
  const refreshToken = response.data.refresh;
  const npToken = response.data.partner_token ? response.data.partner_token : null;
  const npRefresh = response.data.partner_refresh ? response.data.partner_refresh : null;
  setAuthCookies(token, refreshToken, npToken, npRefresh);
  setAuthorizationHeader(token);
  return Promise.resolve(response.data);
}).catch(e => Promise.reject(e.response));
export const getInforUserAction = (email, setCookie = true) => api.auth.getInforUser(email).then(response => {
  if (setCookie) {
    setCookieInforUser(response.data.results);
  }
  return Promise.resolve(response.data);
}).catch(e => Promise.reject(e.response));
export const getUserByEmailAction = (email, setCookie = true) => api.auth.getUserByEmail(email).then(response => {
  if (setCookie) {
    // Pass this as an array in order to use the same function
    setCookieInforUser(response.data);
  }
  return Promise.resolve(response.data);
}).catch(e => Promise.reject(e.response));
export const setNewPasswordAction = data => api.auth.set_new_password(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const resendActivationEmail = data => api.auth.resendActivationEmail(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const passwordReset = data => api.auth.passwordReset(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const logOut = () => api.auth.logOut().then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
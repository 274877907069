import { createContext } from 'react';
import { useLocalStorage } from './useLocalStorage';
const FeatureSwitches = {
  sso_login_page: 'off',
  reportBuilder: false,
  summary: 'off',
  viewRequestsPage: 'off',
  qa_matching: 'on'
};
export const FeaturesContext = createContext(FeatureSwitches);
export const FeaturesProvider = props => {
  const [sso_login_page] = useLocalStorage('sso_login_page', 'pending', 'off');
  const [summary] = useLocalStorage('summary', 'pending', 'off');
  const [viewRequestsPage] = useLocalStorage('viewRequestsPage', 'off', 'off');
  const [qa_matching] = useLocalStorage('qa_matching', 'pending', 'on');
  const FeatureSwitches = {
    sso_login_page: sso_login_page,
    reportBuilder: false,
    summary: summary,
    viewRequestsPage: viewRequestsPage,
    qa_matching: qa_matching
  };
  return <FeaturesContext.Provider value={FeatureSwitches}>
      {props.children}
    </FeaturesContext.Provider>;
};
var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { action, observable } from 'mobx';
import { getTagGroups, getTagTypeOptions } from '@gtintel/platform-api';
import { TAG_COLORS } from '@gtintel/platform-constants';
import { renderTitle } from './utils/tagType';
let TagGroupStore = (_class = class TagGroupStore {
  constructor(rootStore) {
    this.rootStore = void 0;
    _initializerDefineProperty(this, "tagGroups", _descriptor, this);
    _initializerDefineProperty(this, "tagTypeOptions", _descriptor2, this);
    this.rootStore = rootStore;
  }
  async fetchTagGroups(mode) {
    this.tagGroups = await getTagGroups(mode);
  }
  async fetchTagTypeOptionsRecursively(paginationParams) {
    const {
      next,
      results
    } = await getTagTypeOptions(paginationParams);
    const formattedOptions = results.map(option => ({
      key: option.id,
      value: option.name,
      label: renderTitle(option.name, TAG_COLORS[Number(option.code)])
    }));
    if (!paginationParams) {
      this.tagTypeOptions = formattedOptions;
    } else {
      this.tagTypeOptions = [...this.tagTypeOptions, ...formattedOptions];
    }
    if (next) {
      const nextPageParams = new URLSearchParams(next.split('?')[1]);
      if (nextPageParams?.get('offset')) {
        this.fetchTagTypeOptionsRecursively(nextPageParams.toString());
      }
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tagGroups", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tagTypeOptions", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _applyDecoratedDescriptor(_class.prototype, "fetchTagGroups", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTagGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchTagTypeOptionsRecursively", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTagTypeOptionsRecursively"), _class.prototype)), _class);
export default TagGroupStore;
import * as api from '../api';
export const listUsers = data => api.user.list(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const listAdmins = () => api.user.listAdmin().then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getNotificationsStatus = () => api.user.getNotificationsStatus().then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const createUserAction = data => api.user.createUser(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const detailUserAction = id => api.user.detailUser(id).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const updateUserAction = data => api.user.updateUser(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const updateNotificationsStatus = data => {
  api.user.updateNotificationsStatus(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
};
export const getLatestReportsUserAction = data => api.user.getLatestReportsUser(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getApiKey = data => api.user.getApiKey(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const createApiKey = data => api.user.createApiKey(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getPendingPartnerProposal = projectId => api.twilio_chat.getPendingPartnerProposal(projectId).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getAcceptedPartnerProposal = data => api.twilio_chat.getAcceptedPartnerProposal(data).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getListPartnerChat = () => api.user.get_partner_chat().then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getListClientChat = () => api.user.get_client_chat().then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const uploadAvatar = data => {
  const formData = new FormData();
  formData.append('avatar', data.imageFile.avatar);
  formData.append('email', data.email);
  return api.user.uploadAvatar(formData).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
};
import React, { useState } from 'react';
export function useAbortableAsyncOp(fn, options = {
  isLoading: false
}) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(options.isLoading);
  const gn = React.useCallback((...args) => {
    setIsLoading(true);
    const handle = fn(...args);
    const [promise] = handle;
    promise.catch(setError).finally(() => setIsLoading(false));
    return handle;
  }, [fn]);
  return {
    error,
    isLoading,
    fn: gn
  };
}
export function useAsyncOp(fn, options = {
  isLoading: false
}) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(options.isLoading);
  const gn = React.useCallback((...args) => {
    setIsLoading(true);
    const promise = fn(...args);
    promise.catch(setError).finally(() => setIsLoading(false));
    return promise;
  }, [fn]);
  return {
    error,
    isLoading,
    fn: gn
  };
}
import { api } from '..';
import parseResponse from './parseResponse';
export const getMetabaseDashboardUrl = dashboardId => parseResponse(api.quality.getMetabaseDashboardUrl(dashboardId));
export const getUnmappedProjects = () => parseResponse(api.quality.getUnmappedProjects());
export const updateProjectTypes = projectTypes => parseResponse(api.quality.updateProjectTypes(projectTypes));
export const getProjectsWithReport = (limit = 100) => parseResponse(api.quality.getProjectsWithReport(limit));
export const runReportQA = (projectId, reportId, prompt, max_tokens, temperature, top_p) => parseResponse(api.quality.runReportQA(projectId, reportId, prompt, max_tokens, temperature, top_p));
export const getReportQAConfig = () => parseResponse(api.quality.getReportQAConfig());
export const createReportQAConfig = config => parseResponse(api.quality.createReportQAConfig({
  config: config
}));
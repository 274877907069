// <------ auth ------>
export const SELECT_TYPE_USER_PAGE = '/login/users';
export const LOGIN_PAGE = '/login';
export const SET_NEW_PASSWORD = '/auth/set_password';
export const PASSWORD_RESET = '/password-reset';
export const SSO_PAGE = '/sso';

// <------ admin ------>
export const ADMIN_DASHBOARD = '/admin/dashboard';
export const ADMIN_USER = '/admin/user';
export const ADMIN_TAG = '/admin/tag';
export const ADMIN_PROJECT = '/admin/project';
export const ADMIN_MESSAGE = '/admin/message';
export const ADMIN_BatchRequests = '/admin/batchRequests';
export const ADMIN_NOTIFICATION = '/admin/notification';
export const ADMIN_PROJECT_NEW = '/admin/project/new';
export const ADMIN_PROJECT_DETAIL = '/admin/project/detail';
export const ADMIN_PROJECT_DETAIL_NEW = 'admin/project';
export const ADMIN_PROJECT_PROPOSAL = '/admin/project/proposal';
export const ADMIN_REQUESTS_HOME = '/admin/requests';
export const ADMIN_QA_HOME = '/admin/quality/insights';

// <------ client ------>
export const CLIENT_DASHBOARD = '/client/dashboard';
export const CLIENT_MY_ACCOUNT = '/client/my-account';
export const CLIENT_USER = '/client/user';
export const CLIENT_TAG = '/client/tag';
export const CLIENT_PROJECT = '/client/project';
export const CLIENT_MESSAGE = '/client/message';
export const CLIENT_NOTIFICATION = '/client/notification';
export const CLIENT_PROJECT_NEW = '/client/project/new';
export const CLIENT_PROJECT_DETAIL = '/client/project/detail';
export const CLIENT_PROJECT_PROPOSAL = '/client/project/proposal';

// <------ partner ------>
export const PARTNER_DASHBOARD = '/partner/dashboard';
export const PARTNER_PROFILE = '/partner/profile';
export const PARTNER_USER = '/partner/user';
export const PARTNER_TAG = '/partner/tag';
export const PARTNER_PROJECT = '/partner/project';
export const PARTNER_REQUESTS_HOME = '/partner/requests';
export const PARTNER_ASSIGNMENT = '/partner/assignments';
export const PARTNER_MESSAGE = '/partner/message';
export const PARTNER_NOTIFICATION = '/partner/notification';
export const PARTNER_PROJECT_NEW = '/partner/project/new';
export const PARTNER_PROJECT_DETAIL = '/partner/project/detail';
export const PARTNER_PROJECT_PROPOSAL = '/partner/project/proposal';